import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { GlobalSettings } from '../settings';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router) { }

  endpoint: string = "login";
  jwtHelper = new JwtHelperService();
  @Output() emitLoggedIn: EventEmitter<any> = new EventEmitter();

  login(loginData) {
    return this.http.post(this.endpoint , loginData);
  }

  changePassword(passwordData){
    return this.http.put("user/change", passwordData);
  }

  loggedInOrOutEmit(){
    this.emitLoggedIn.emit(true);
  }

  saveToken(token){
    localStorage.setItem("token", token);
  }

  isLoggedIn():boolean{
    var token = localStorage.getItem("token");
    if(token){
      return !this.jwtHelper.isTokenExpired(token);
    }else{
      return false;
    }
  }


  isAdmin():boolean{
    var token = localStorage.getItem("token");
    var decodedToken = this.jwtHelper.decodeToken(token);
    if(decodedToken.user.role == 'admin'){
      return true;
    }else{
      return false;
    }
  }

  decodedToken(){
    var token = localStorage.getItem("token");
    return this.jwtHelper.decodeToken(token);
  }


  logout(){
    localStorage.removeItem("token");
    this.loggedInOrOutEmit();
    this.router.navigate(['login']);
  }

}
