import { Injectable, NgModule } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalSettings } from '../settings';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //START - BASE URL Implementation

        var BASE_URL = GlobalSettings.API_ENDPOINT;

        req = req.clone(
                {
                    url: BASE_URL + req.url
                }
            );

        //END - BASE URL Implementation

        
        //START - Token Header Implementation
        var token = localStorage.getItem("token");

        if(token){
            const newReq = req.clone(
                {
                    headers:req.headers.set('Authorization',
                                            'Bearer ' + token)
                }
            );

            return next.handle(newReq);

        }else{
            return next.handle(req);            
        }

         //END - Token Header Implementation
    }
}
