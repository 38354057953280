import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core-module/guards/auth-guard.service';
import { LoggedGuardService } from './core-module/guards/logged-guard.service';

const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
  // { path: 'home', component: HomeComponent },
  // { path: 'home/customers', loadChildren: './feature-modules/customer/customer.module#CustomerModule' },
  // { path: '', loadChildren: () => import('./feature-modules/layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./feature-modules/layout/layout.module').then(m => m.LayoutModule)  },
  { path: 'login', loadChildren: () => import('./feature-modules/login/login.module').then(m => m.LoginModule), canActivate:[LoggedGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
