import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { HttpRequestInterceptor } from './interceptors/http-interceptor';
import { AuthGuardService } from './guards/auth-guard.service';
import { AdminGuardService } from './guards/admin-guard.service';
import { LoggedGuardService } from './guards/logged-guard.service';


@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
  ],
  exports:[
    CommonModule,
  ],
  declarations: [
  ],
  providers:[
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    AuthGuardService,
    AdminGuardService,
    LoggedGuardService
  ],
})
export class CoreModule { }
